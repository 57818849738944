<template>
  <div class="success-container container d-flex flex-column align-items-center">
    <img class="success-image" src="../assets/400p.png" alt="">
    <h1 class="success-title">
      Success
    </h1>
    <p class="text-center" v-if="!$store.state.credentials.deeplink">
      If you didn't find our email with instructions, please check spam and other email folders
      or contact us via <a href="mailto:support@mathmaster.me">support@mathmaster.me</a>
    </p>
    <a :href="$store.state.credentials.deeplink"
       v-if="$store.state.credentials.deeplink"
       class="btn btn-primary site-btn site-btn_primary">
      Continue
    </a>
  </div>
</template>

<script>
import { getCookie } from '@/main';

export default {
  name: 'Success',
  created() {
    if (!this.$store.state.credentials.password && getCookie('appLink')) {
      window.open(getCookie('appLink'), '_self');
    }
  },
};
</script>

<style lang="scss" scoped>
.success {
  &-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &-image {
    max-width: 450px;
    width: 100%;
  }

  &-title {
    font-size: 2rem;
  }

  &-button {
    font-size: 1rem;
  }
}

</style>
